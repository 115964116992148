.App {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p{
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.connect-container {
    display: flex;
}

.connect-button {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 40px;
}
.title-description {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.title-description .title {
    font-weight: 300;
    font-size: 30px;
    line-height: 130%;
}
.title-description .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
}

.button {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 16px;
    border: 1px solid #212121;
    border-radius: 15px;
    gap: 8px;
    width: 300px;
    cursor: pointer;
}
.button:hover {
    background: #212121;
    color: white;
}

.wallet-container {
    padding-top: 40px;
    display: flex;
    gap: 24px;
}

.wallet {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #212121;
    border-radius: 15px;
    color: #212121;
    padding: 16px 40px;
    cursor: pointer;
}
.wallet:hover {
    background: #212121;
    color: white;
}
.link {
    position: relative;
}
.link::before {
    content: '';
    position: absolute;
    top: -3.5px;
    left: 0;
    height: 1px;
    width: 100%;
    background: white;
}
.link::after {
    content: '';
    position: absolute;
    bottom: -3.5px;
    left: 0;
    height: 1px;
    width: 100%;
    background: white;
}

.download-container {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #212121;
    display: flex;
    flex-direction: column;
}



.download-link {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #212121;
    text-decoration: none;
    padding: 16px;
    border: 1px solid #212121;
    border-radius: 15px;
}
.download-link:hover {
    background: #212121;
        color: white;
}

.account {
    color: #212121;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    padding-bottom: 20px;
}


.error {
    color: #212121;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 20px;
}

.success {
    font-size: 14px;
    line-height: 18px;
    padding-top: 20px;
    color: #212121;
    font-weight: 400;
}
.cross {
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.arrow {
    height: 15px;
    width: 15px;
    transform: rotate(180deg);
    cursor: pointer;
}

.test {
    display: flex;
    justify-content: space-between;
    gap: 600px;
    align-items: center;
}
.error-message {
    display: flex;
    gap: 10px;
}